@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.error-div-center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); //this is for new IE
    -webkit-transform: translate(-50%, -50%); // this is for Safari
    max-width: 377px;
}

.button-center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0.3571em 1.1429em;
}

.heading-text {
    color: #545B64;
}

.icon-center {
    display: flex;
    justify-content: center;
}