@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.quickSightContent {
  padding-top: 100px;
  padding-bottom: 100px;
}

.report-header {
  margin-bottom: 15px;
}
 
.print-button {
  float: right;
  margin-bottom: 0px;
  margin-top: 20px;
  margin-right: 15px;
}
 
#embeddingContainer {
  background-color: white;
}
