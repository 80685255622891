@use '@amzn/awsui-design-tokens/polaris.scss' as awsui;

.header-wrapper {
	/* 
   * Allows fixed header to show on top of all other items
   * such as the popover div for copy to clipboard confirmation
   */
	/* Polaris requires sticky header */
	position: sticky;
	position: -webkit-sticky;
	top: 0;
	width: 100%;
	z-index: 3000; /* Polaris sets popover to 2000 */
}

.bifrost-header {
	.header__topnav_desktop,
	.header__topnav_mobile {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
	}

	.header__topnav_desktop_hidden,
	.header__topnav_mobile_hidden {
		display: none;
	}
}

.header__branding,
.header__actions {
	align-self: center;
}

.header__link_focus {
	&:focus-visible {
		outline: #00a1c9 solid 2px;
	}
}

.header__branding img {
	max-height: 4rem;
	width: 16rem;
}

.header__usermenu {
	padding-left: 2.4rem;
}

.header__usermenutruncate {
	max-width: 200px;
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	vertical-align: bottom;
}

.header__vertmobilenav {
	position: fixed;
	top: 8.4rem;
	right: 0;
	left: 0;
	bottom: 0;
	z-index: 4000;
	transition: all 180ms;
	height: 100%;
	background-color: awsui.$color-background-container-content;

	&_closed {
		transform: translateX(-100%);
		visibility: hidden;
		// prevent non-visible elements inside this hidden closed menu from receiving focus
		& * {
			visibility: hidden;
		}
	}

	&_content {
		position: relative;
	}
}
